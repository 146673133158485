import React, { ReactElement, useState } from 'react';

import {
  cart,
  cartFilled,
  favourite,
  favouriteFilled,
  forward,
  pause,
  play,
  search,
  user,
  volume,
} from './icons';

const getIcon = (icon, colorStroke, colorFill): { viewBox: string; code: ReactElement } => {
  const params = { colorStroke, colorFill };
  switch (icon) {
    case 'cart':
      return cart(params);
    case 'cartFilled':
      return cartFilled(params);
    case 'favourite':
      return favourite(params);
    case 'forward':
      return forward(params);
    case 'favouriteFilled':
      return favouriteFilled(params);
    case 'pause':
      return pause(params);
    case 'play':
      return play(params);
    case 'search':
      return search(params);
    case 'user':
      return user(params);
    case 'volume':
      return volume(params);
    default:
      throw new Error('Invalid icon');
  }
};

const Icon = ({
  name,
  active,
  hoverColor,
  colorStroke,
  colorFill,
  viewBox,
  width,
  height,
  ...props
}: {
  name: string;
  active?: string | null;
  hoverColor?: string;
  colorStroke?: string | null;
  colorFill?: string | null;
  viewBox?: string | null;
  width?: string | number;
  height?: string | number;
}) => {
  const [hover, setHover] = useState(false);
  let stroke = hover && hoverColor ? hoverColor : colorStroke;
  let fill = hover && hoverColor ? hoverColor : colorFill;
  if (active) {
    stroke = active;
    fill = active;
  }
  return (
    <svg
      role="img"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || getIcon(name, colorStroke, colorFill).viewBox}
      width={width}
      height={height}
      {...props}
    >
      {getIcon(name, stroke, fill).code}
    </svg>
  );
};

Icon.defaultProps = {
  colorStroke: null,
  active: null,
  hoverColor: null,
  colorFill: null,
  viewBox: null,
  width: '24',
  height: '24',
};

export default Icon;
