import { Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

export const SearchMainForm = styled.div<{ active: boolean }>`
  width: 100%;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  padding: ${({ active }) => (active ? '32px 0' : '0')};
  overflow: hidden;
  height: ${({ active }) => (active ? '104px' : 0)};
  opacity: ${({ active }) => (active ? 1 : 0)};
  box-shadow: ${({ active }) =>
    active ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 0 0 rgba(0, 0, 0, 0)'};
  background-color: var(--white, #ffffff);
  transition: all 300ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: ${({ active }) => (active ? '0 0 24px' : '0')};
    height: ${({ active }) => (active ? '80px' : 0)};
  }
`;

export const SearchForm = styled.div`
  width: 100%;
  max-width: 358px;
  @media (min-width: 911px) {
    display: none;
  }
`;

export const Badge = styled.span`
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: var(--black, #000000);
  color: var(--white);
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.4px;
  @media (max-width: 768px) {
    bottom: 4px;
    right: 4px;
  }
`;

export const NavBar = styled.nav<{ white: boolean; height: number; position: number }>`
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition:
    top 500ms ease-in-out,
    box-shadow 500ms ease-in-out,
    background 600ms 100ms ease;
  position: relative;
  &.hidden {
    position: fixed;
    transition: ${({ height, position }) =>
      height < position
        ? 'top 500ms ease-in-out, box-shadow 500ms ease-in-out, background 600ms 100ms ease'
        : 'none'};
    top: ${({ height }) => `-${height}px`};
  }
  &.visible {
    position: fixed;
    top: 0;
  }
  &.navbar {
    box-shadow: none;
    background: rgba(255, 255, 255, 0);

    &:after {
      display: block;
      position: absolute;
      z-index: 1;
      content: '';
      width: 100%;
      height: 100%;
      background: ${({ white }) => (white ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0)')};
      bottom: 0;
      left: 0;
      transition: background 300ms ease;
    }

    &.hidden {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0);
      background: rgba(255, 255, 255, 0);
    }

    &.visible {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 1);
    }
  }
`;

export const StaticNavBar = styled.div<{ height: number }>`
  position: absolute;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  &.static {
    position: relative;
  }
`;

export const NavBlock = styled.nav`
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  padding: 32px 28px;
  position: relative;
  background: none;
  z-index: 2;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1.5fr 1fr;
    padding: 24px 20px;
  }
`;

export const topToCenter = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(45deg);
  }
`;

export const bottomToCenter = keyframes`
  0% {
      transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(0) rotate(0);
  }
  100% {
      transform: translateY(0) rotate(-45deg);
  }
`;

export const topFromCenter = keyframes`
  0% {
    transform: translateY(0) rotate(45deg);
  }
  50% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
`;

export const bottomFromCenter = keyframes`
  0% {
     transform: translateY(0) rotate(-45deg);
  }
  50% {
    transform: translateY(0) rotate(0);
  }
  100% {
      transform: translateY(0) rotate(0);
  }
`;

export const ButtonMobile = styled.button<{ active: boolean; visibility: string }>`
  background-color: transparent;
  border: none;
  z-index: 1000;
  padding: 0;
  cursor: pointer;
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  span {
    display: flex;
    width: 18px;
    height: 2px;
    background-color: ${({ visibility, active }) =>
      visibility === 'visible' && !active ? 'var(--white)' : 'var(--dark)'};
    margin-bottom: 5px;
    transition: all 300ms ease;
    position: relative;

    &:nth-child(1) {
      top: ${({ active }) => (active ? '29%' : '0')};
      animation: ${({ active }) => (active ? topToCenter : topFromCenter)} 500ms forwards;
    }

    &:nth-child(2) {
      opacity: ${({ active }) => (active ? '0' : '1')};
    }

    &:nth-child(3) {
      width: ${({ active }) => (active ? 18 : 13)}px;
      bottom: ${({ active }) => (active ? '29%' : 0)};
      animation: ${({ active }) => (active ? bottomToCenter : bottomFromCenter)} 500ms forwards;
    }
  }
  @media (max-width: 911px) {
    display: block;
  }
`;

export const MenuWrap = styled.div`
  display: flex;
  .search-icon-mobile {
    display: none;
  }
  .menu-icon {
    display: none;
  }
  @media (max-width: 911px) {
    align-items: center;
    .menu-icon {
      display: block;
    }
    .search-icon-mobile {
      display: block;
      margin-left: 12px;
    }
  }
`;

export const MenuArea = styled.div<{ active: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 911px) {
    position: fixed;
    z-index: 999;
    left: 0;
    top: ${({ active }) => (active ? 0 : '-100vh')};
    background-color: var(--white);
    width: 100%;
    height: 100vh;
    padding: 100px 20px;
    overflow-y: auto;
    transition: top 300ms ease;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MenuItems = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  @media (max-width: 911px) {
    min-width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const LogoMobile = styled.img`
  display: none;
  @media (max-width: 911px) {
    display: block;
    width: 194px;
    height: auto;
    margin-bottom: 60px;
  }
`;

export const MenuItem = styled.li`
  position: relative;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  &:hover > a {
    color: var(--red);
  }
  cursor: pointer;
`;

export const MenuLink = styled(Link)<{ visibility: string }>`
  text-decoration: none;
  color: ${({ visibility }) => (visibility === 'visible' ? 'var(--white)' : 'var(--dark)')};
  padding: 8px 12px;
  display: block;
  transition: color 0.3s ease;

  &:hover {
    color: var(--red);
  }
  @media (max-width: 1300px) {
    padding: 8px;
  }
  @media (max-width: 1170px) {
    padding: 6px;
    font-size: 12px;
  }
  @media (max-width: 1100px) {
    padding: 5px;
    font-size: 10px;
  }
  @media (max-width: 911px) {
    color: var(--dark);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 32px;
  }
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: none;
  min-width: 150px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
    display: block;
  }
`;

export const DropdownItem = styled.li`
  padding: 0;
`;

export const DropdownLink = styled(Link)<{ visibility: string }>`
  text-decoration: none;
  color: ${({ visibility }) => (visibility === 'string' ? 'var(--white)' : 'var(--dark)')};
  padding: 8px 16px;
  display: block;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
    color: #007bff;
  }
`;

export const Logo = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 998;
  img {
    position: relative;
    width: 194px;
    height: auto;
  }
  @media (max-width: 1300px) {
    img {
      width: 140px;
    }
  }
  @media (max-width: 768px) {
    min-width: 105px;
    img {
      width: 105px;
      right: ${({ active }) => (active ? '-50%' : '0')};
      transition: ${({ active }) =>
        !active ? 'none' : 'transform 400ms ease-in-out, right 400ms ease-in-out'};
      position: relative;
      transform: ${({ active }) =>
        active ? 'translate(-50%, 200%) scale(2)' : 'translate(0, 0) scale(1)'};
    }
  }
`;

export const MobileMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 911px) {
    min-width: 108px;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  @media (max-width: 911px) {
    align-items: center;
    .search-icon {
      display: none;
    }
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1;

  svg {
    width: 36px;
    height: 36px;
  }
  @media (max-width: 911px) {
    svg {
      width: 42px;
      height: 42px;
    }
  }
  @media (max-width: 768px) {
    padding: 0;
    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

export const SocialContainer = styled.aside`
  display: flex;
  flex-direction: column;
  min-width: 407px;
  grid-template-areas: 'a b';
  @media (max-width: 1300px) {
    min-width: 350px;
  }
  @media (max-width: 1170px) {
    flex-direction: row;
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'b a';
    gap: 20px;
  }
  @media (max-width: 500px) {
    gap: 10px;
  }
  @media (max-width: 390px) {
    gap: 10px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'b'
      'a';
  }
`;

export const SocialArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
  &:first-child {
    grid-area: a;
  }
  &:last-child {
    grid-area: b;
  }
  @media (max-width: 1170px) {
    margin-bottom: 0;
    margin-right: 40px;
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 40px;
    margin-right: 0;
  }
`;

export const SocialTitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export const SocialItem = styled.a`
  display: block;
  margin-right: 8px;
`;

export const Socials = styled.div`
  display: flex;
`;

export const BottomMenu = styled.footer`
  padding: 52px 0 42px;
  background-color: var(--footer, #f2f2f2);
`;

export const BottomMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
`;

export const MenuFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 130px;
  @media (max-width: 1300px) {
    margin-right: 50px;
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  @media (max-width: 500px) {
    gap: 10px;
  }
  @media (max-width: 370px) {
    grid-template-columns: 1fr;
  }
`;

export const MenuColumn = styled.div`
  flex: 1;
`;

export const TopPart = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 45px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const BottomPart = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 44px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  @media (max-width: 1170px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 407px;
  @media (max-width: 1300px) {
    max-width: 350px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Subscribe = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FormInline = styled(Form)`
  display: flex;
  margin-bottom: 10px;

  input {
    border-radius: 0;
    width: 100%;
    display: flex;
    height: 44px;
    padding-left: 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border: 1px solid rgba(70, 70, 70, 0.2);
    background: rgba(255, 255, 255, 0.3);

    &:focus-visible {
      outline-offset: 0;
      border: 1px solid var(--dark, #000);
      background: rgba(255, 255, 255, 1);
      outline: none;
    }
  }
`;

export const SubscribeButton = styled.button`
  background: var(--black, #000);
  padding: 10px 20px;
  color: var(--white, #fff);
  border: none;
  font-size: 12px;
  font-weight: 500;
  &:hover,
  &:focus {
    transition: background 0.3s;
    background: var(--hover-dark, #333);
  }
`;

export const StyledField = styled(Field)`
  padding: 8px;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const SubscribeLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const SubscribeText = styled.p`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
  letter-spacing: 0.4px;
  opacity: 0.6;
`;

export const StyledErrorMessage = styled.div`
  color: var(--error, #dd463c);
  font-size: 12px;
  position: absolute;
  left: 0;
  bottom: -7px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const LogoFooter = styled.div`
  img {
    width: 196px;
    height: auto;
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
    img {
      width: 172px;
      height: auto;
    }
  }
`;

export const MenuTitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export const MenuLinkFooter = styled(Link)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: var(--black, #000);
  display: block;
  text-decoration: none;
  margin-bottom: 12px;
  &:hover {
    text-decoration: underline; /* Подчеркивание при наведении */
  }
`;

export const Copyright = styled.div`
  color: var(--black, #000);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  opacity: 0.6;
  margin-top: 12px;
`;
