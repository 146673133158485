// This is a list of statuses
export const STATUS_ACTIVE: string = 'active';
export const STATUS_INACTIVE: string = 'inactive';
export const STATUS_DELETED: string = 'deleted';

// This is list of input types
export const INPUT_TYPE_TEXT: string = 'text';
export const INPUT_TYPE_TEXTAREA: string = 'textarea';
export const INPUT_TYPE_EDITOR: string = 'editor';

// This is list of filter types
export const FIELD_TYPE_COLOR: string = 'color';
export const FIELD_TYPE_RADIO: string = 'radio';
export const FIELD_TYPE_CHECKBOX: string = 'checkbox';
export const FIELD_TYPE_CHECK_BTN: string = 'check_button';

// This is list of filter types
export const FILTER_CATEGORY: string = 'category';
export const FILTER_SUBCATEGORY: string = 'subcategory';
export const FILTER_BRAND: string = 'brand';
export const FILTER_COLOR: string = 'color';
export const FILTER_PRICE: string = 'price';

// This is a list of reserved slugs for first level
export const SLUG_HOME: string = 'home';
export const SLUG_LOGIN: string = 'login';
export const SLUG_LOGOUT: string = 'logout';
export const SLUG_SIGNUP: string = 'signup';
export const SLUG_ACCOUNT: string = 'account';
export const SLUG_CATALOG: string = 'catalog';
export const SLUG_PRODUCT: string = 'product';
export const SLUG_FAVORITES: string = 'favorites';
export const SLUG_ORDER: string = 'order';
export const SLUG_DELIVERY: string = 'delivery-terms';
export const SLUG_PAYMENT: string = 'payment-terms';
export const SLUG_PRIVACY: string = 'privacy-policy';

// Image sizes
export const IMAGE_SIZE_SMALL: number = 250;
export const IMAGE_SIZE_MEDIUM: number = 600;

// Social types
export const SOCIAL_CHANNEL: string = 'channel';
export const SOCIAL_CONTACT: string = 'contact';

export const ORDER_NEW = 'new';
export const ORDER_PROCESSING = 'processing';
export const ORDER_COMPLETED = 'completed';
export const ORDER_CANCELED = 'canceled';

export const ORDER_STATUSES = {
  new: ORDER_NEW,
  processing: ORDER_PROCESSING,
  completed: ORDER_COMPLETED,
  canceled: ORDER_CANCELED,
};

// This is a list of named order statuses
export const ORDER_STATUSES_NAMES = {
  [ORDER_NEW]: 'Оформлен',
  [ORDER_PROCESSING]: 'В обработке',
  [ORDER_COMPLETED]: 'Выполнен',
  [ORDER_CANCELED]: 'Отменен',
};

// This is a list of user social types
export const SOCIAL_TYPES: { [key: string]: string } = {
  [SOCIAL_CHANNEL]: 'Официальные каналы',
  [SOCIAL_CONTACT]: 'Связаться с нами',
};

// This is a list of reserved slugs
export const RESERVED_SLUGS: string[] = [
  SLUG_LOGIN,
  SLUG_LOGOUT,
  SLUG_SIGNUP,
  SLUG_ACCOUNT,
  SLUG_CATALOG,
];

// This is a list of field types for the characteristic
export const FIELD_TYPES: { [key: string]: string } = {
  color: FIELD_TYPE_COLOR,
  radio: FIELD_TYPE_RADIO,
  checkbox: FIELD_TYPE_CHECKBOX,
  check_button: FIELD_TYPE_CHECK_BTN,
};

// This is a list of user statuses
export const USER_STATUSES: { [key: string]: string } = {
  active: STATUS_ACTIVE,
  inactive: STATUS_INACTIVE,
  deleted: STATUS_DELETED,
};

// This is a list of user roles
export const INPUT_TYPES: { [key: string]: string } = {
  [INPUT_TYPE_TEXT]: 'Текст',
  [INPUT_TYPE_TEXTAREA]: 'Текстовая область',
  [INPUT_TYPE_EDITOR]: 'Редактор',
};

// This is a list of statuses
export const STATUSES: { [key: string]: string } = {
  active: STATUS_ACTIVE,
  inactive: STATUS_INACTIVE,
};

// This is a list of statuses text
export const USER_STATUSES_TEXT: { [key: string]: string } = {
  [STATUS_ACTIVE]: 'Активный',
  [STATUS_INACTIVE]: 'Неактивный',
  [STATUS_DELETED]: 'Удален',
};
