import React, { ReactElement } from 'react';

const forward = ({ colorFill }: IconParams): { viewBox: string; code: ReactElement } => ({
  viewBox: '0 0 14 8',
  code: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.146447 4.35389C-0.0488155 4.15863 -0.0488155 3.84205 0.146447 3.64679L3.32843 0.464806C3.52369 0.269544 3.84027 0.269544 4.03553 0.464806C4.2308 0.660068 4.2308 0.976651 4.03553 1.17191L1.70711 3.50034H13.5V4.50034H1.70711L4.03553 6.82877C4.2308 7.02403 4.2308 7.34061 4.03553 7.53587C3.84027 7.73114 3.52369 7.73114 3.32843 7.53587L0.146447 4.35389Z"
      fill={colorFill}
    />
  ),
});

export { forward };
