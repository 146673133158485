import React, { ReactElement } from 'react';

const volume = ({ colorStroke }: IconParams): { viewBox: string; code: ReactElement } => ({
  viewBox: '0 0 32 32',
  code: (
    <g>
      <circle cx="16" cy="16" r="15.5" fill="none" stroke={colorStroke} />
      <path
        d="M11.0246 22.0343L11.0936 8.03447"
        stroke={colorStroke}
        fill="none"
        strokeLinecap="round"
      />
      <path
        d="M16.0245 22.059L16.0738 12.0591"
        stroke={colorStroke}
        fill="none"
        strokeLinecap="round"
      />
      <path
        d="M21.0244 22.0836L21.054 16.0837"
        stroke={colorStroke}
        fill="none"
        strokeLinecap="round"
      />
    </g>
  ),
});

export { volume };
