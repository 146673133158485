import { gql } from '@apollo/client';
import { settingFragment } from './setting';

export const GQL_SEARCH = gql`
  query Search($query: String!) {
    search: frontendSearch(query: $query) {
      _id
      name
      slug
      price
      # Virtuals
      color {
        name
      }
      files {
        path
        mime_type
        model
        model_id
        format
      }
    }
  }
`;

export const GQL_GET_ADDRESS = gql`
  query GetAddresses {
    addresses: frontendAddressList {
      _id
      name
      city
      location
      description
    }
  }
`;

export const socialFragment = gql`
  fragment SocialFragment on Social {
    _id
    name
    code
    url
    type
    status
  }
`;

export const menuFragment = gql`
  fragment MenuItemFragment on MenuItem {
    _id
    name
    url
    order
    status
    children {
      name
      url
      order
      status
    }
  }
`;

export const metaFragment = gql`
  fragment MetaFragment on MetaTags {
    title
    description
    canonical
    robots
  }
`;

export const colorFragment = gql`
  fragment ColorFragment on Color {
    _id
    name
    code
    slug
    status
  }
`;

export const fileFragment = gql`
  fragment FileFragment on File {
    _id
    path
    mime_type
    model
    model_id
    format
    size
    height
    width
    order
  }
`;

export const GQL_GET_MAIN_CONTENT = gql`
  query GetMainContent {
    header: frontendSetting(code: "head_block") {
      ...SettingFragment
    }
    footer: frontendSetting(code: "footer_block") {
      ...SettingFragment
    }
    label: frontendSetting(code: "footer_subscribe_label") {
      ...SettingFragment
    }
    text: frontendSetting(code: "footer_subscribe_text") {
      ...SettingFragment
    }
    socials: frontendSocials {
      ...SocialFragment
    }
    mainMenu: frontendMenu(code: "main_menu") {
      _id
      name
      code
      status
      items {
        ...MenuItemFragment
      }
    }
    footerMenu: frontendMenu(code: "footer_menu") {
      _id
      name
      code
      status
      items {
        ...MenuItemFragment
      }
    }
  }
  ${menuFragment}
  ${socialFragment}
  ${settingFragment}
`;
