import React, { ReactElement } from 'react';

const play = ({ colorStroke }: IconParams): { viewBox: string; code: ReactElement } => ({
  viewBox: '0 0 32 32',
  code: (
    <g>
      <circle cx="16" cy="16" r="15.5" fill="none" stroke={colorStroke} />
      <path
        d="M20.3621 16L13.5765 11.28V20.72L20.3621 16Z"
        stroke={colorStroke}
        fill="none"
        strokeWidth="1.2"
      />
    </g>
  ),
});

export { play };
