import { ErrorMessage, Formik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import React from 'react';
import _ from 'lodash';

import { SOCIAL_CHANNEL, SOCIAL_CONTACT, SOCIAL_TYPES } from '../helpers/constants';
import {
  BottomMenu,
  BottomMenuContainer,
  BottomPart,
  Copyright,
  FormInline,
  LeftSide,
  LogoFooter,
  MenuColumn,
  MenuFooter,
  MenuLinkFooter,
  MenuTitle,
  RightSide,
  SocialArea,
  SocialContainer,
  SocialItem,
  Socials,
  SocialTitle,
  StyledErrorMessage,
  StyledField,
  Subscribe,
  SubscribeButton,
  SubscribeLabel,
  SubscribeText,
  TopPart,
} from './_styles';

const Footer = ({ content }: { content: object }) => {
  const [emailInit, setEmail] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const label = _.get(content, 'label', '');
  const text = _.get(content, 'text', '');
  const onSubmit = (values: any): void => {
    const { email } = values;
    if (!email) {
      return;
    }
    enqueueSnackbar('Спасибо! Вы успешно подписаны!', { variant: 'success' });
    setEmail('');
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Введите корректный email!').required('E-mail обязательное поле'),
  });

  return (
    <BottomMenu>
      <BottomMenuContainer>
        <TopPart>
          <LeftSide>
            <LogoFooter>
              <img src="/assets/images/logo-dark.svg" alt="Logo" />
            </LogoFooter>
          </LeftSide>
          <RightSide>
            <SubscribeLabel>{_.get(label, 'value', '')}</SubscribeLabel>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{ email: emailInit }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Subscribe>
                  <FormInline>
                    <StyledField
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      value={emailInit}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <SubscribeButton as="button" type="submit" disabled={isSubmitting}>
                      Отправить
                    </SubscribeButton>
                  </FormInline>
                  <StyledErrorMessage>
                    <ErrorMessage name="email" />
                  </StyledErrorMessage>
                </Subscribe>
              )}
            </Formik>
            <SubscribeText>{_.get(text, 'value')}</SubscribeText>
          </RightSide>
        </TopPart>
        <BottomPart>
          <MenuFooter>
            {_.get(content, 'footerMenu.items', []).map(
              (item) =>
                item.children &&
                item.children.length > 0 && (
                  <MenuColumn as="div" key={item._id}>
                    <MenuTitle>{item.name}</MenuTitle>
                    {item.children.map((child: MenuItem) => (
                      <MenuLinkFooter key={child.url} to={child.url}>
                        {child.name}
                      </MenuLinkFooter>
                    ))}
                  </MenuColumn>
                )
            )}
          </MenuFooter>
          {_.get(content, 'socials', []).length > 0 && (
            <SocialContainer>
              {_.get(content, 'socials', []).some((ss) => ss.type === SOCIAL_CHANNEL) && (
                <SocialArea>
                  <SocialTitle>{SOCIAL_TYPES[SOCIAL_CHANNEL]}</SocialTitle>
                  <Socials>
                    {_.get(content, 'socials', [])
                      .filter((sf) => sf.type === SOCIAL_CHANNEL)
                      .map((social) => (
                        <SocialItem
                          as="a"
                          href={social.url}
                          key={social._id}
                          rel="noopener nofollow noreferrer"
                          dangerouslySetInnerHTML={{ __html: social.code }}
                        />
                      ))}
                  </Socials>
                </SocialArea>
              )}
              {_.get(content, 'socials', []).some((ss) => ss.type === SOCIAL_CONTACT) && (
                <SocialArea>
                  <SocialTitle>{SOCIAL_TYPES[SOCIAL_CONTACT]}</SocialTitle>
                  <Socials>
                    {_.get(content, 'socials', [])
                      .filter((sf) => sf.type === SOCIAL_CONTACT)
                      .map((social) => (
                        <SocialItem
                          as="a"
                          href={social.url}
                          key={social._id}
                          rel="noopener nofollow noreferrer"
                          dangerouslySetInnerHTML={{ __html: social.code }}
                        />
                      ))}
                  </Socials>
                </SocialArea>
              )}
            </SocialContainer>
          )}
        </BottomPart>
        <Copyright>© Copyright {moment().format('YYYY')} Khokhloma</Copyright>
      </BottomMenuContainer>
    </BottomMenu>
  );
};

export default Footer;
