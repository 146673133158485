import { ErrorResponse, onError } from '@apollo/client/link/error';
import express from 'express';
import { get } from 'lodash';

export const errorsGQL = (res: express.Response) =>
  onError(({ graphQLErrors, networkError }: ErrorResponse) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((err) => {
        const code = Number(get(err, 'extensions.code', 500));
        const redirect = get(err, 'extensions.redirect', null);
        switch (code) {
          case 301:
          case 302:
            if (res && redirect) {
              res.redirect(code, redirect);
            }
            break;
          case 400:
          case 401:
          case 402:
          case 403:
          case 404:
            if (res) {
              res.status(code);
            }
            break;
          default:
            // eslint-disable-next-line no-console
            console.log(err);
            break;
        }
      });
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(networkError);
    }
  });
