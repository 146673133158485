import React, { ReactElement } from 'react';

const favouriteFilled = ({ colorFill }: IconParams): { viewBox: string; code: ReactElement } => ({
  viewBox: '0 0 36 36',
  code: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={`M14.1292 12.3005C13.0059 12.6105 12.1687 
        13.4337 11.7358 14.654C11.5833 15.0837 11.5765 
        15.1363 11.5753 15.8939C11.5741 16.5875 11.5886 
        16.731 11.6925 17.0561C11.9019 17.7111 12.1684 18.1396 
        12.7604 18.7734C13.7605 19.844 17.8656 23.7814 17.9817 
        23.7814C18.0555 23.7814 18.9334 22.963 20.802 21.152C22.2943 
        19.7058 23.5912 18.4207 23.684 18.2962C23.9222 17.9767 24.169 
        17.4849 24.3075 17.0539C24.4114 16.7305 24.4259 16.5878 24.4247 
        15.8939C24.4235 15.1363 24.4167 15.0837 24.2642 14.654C23.7075 
        13.0851 22.4241 12.137 20.9721 12.2221C20.1256 12.2716 19.445 
        12.6357 18.5566 13.5141L18 14.0645L17.4434 13.5141C16.8403 
        12.9178 16.3521 12.5766 15.8123 12.3743C15.3497 12.201 14.6076 
        12.1684 14.1292 12.3005ZM15.5777 12.8466C16.1707 13.042 16.9728 
        13.6787 17.5439 14.4073C17.9716 14.9531 18.0285 14.953 18.4579 
        14.4051C19.0513 13.6479 19.887 12.9994 20.5046 12.8168C21.7724 
        12.442 23.1913 13.2753 23.7231 14.7072C24.1031 15.7303 23.9456 
        17.0102 23.3329 17.8775C23.116 18.1846 18.0905 23.1386 17.9959 
        23.1386C17.9656 23.1386 16.7701 21.9866 15.3393 20.5785C12.6015 
        17.8844 12.5197 17.7917 12.2767 17.109C11.9997 16.3304 11.9992 
        15.4549 12.2753 14.7115C12.6663 13.6586 13.5648 12.8817 
        14.5593 12.7364C14.8031 12.7008 15.2973 12.7543 15.5777 12.8466Z`}
        fill={colorFill}
      />
      <path
        d={`M15.5777 12.8466C16.1707 13.042 16.9728 
        13.6787 17.5439 14.4073C17.9716 14.9531 18.0285 
        14.953 18.4579 14.4051C19.0513 13.6479 19.887 12.9994 
        20.5046 12.8168C21.7724 12.442 23.1913 13.2753 23.7231 
        14.7072C24.1031 15.7303 23.9456 17.0102 23.3329 17.8775C23.116 
        18.1846 18.0905 23.1386 17.9959 23.1386C17.9656 23.1386 16.7701 
        21.9866 15.3393 20.5785C12.6015 17.8844 12.5197 17.7917 12.2767 
        17.109C11.9997 16.3304 11.9992 15.4549 12.2753 14.7115C12.6663 
        13.6586 13.5648 12.8817 14.5593 12.7364C14.8031 12.7008 15.2973 
        12.7543 15.5777 12.8466Z`}
        fill={colorFill}
      />
    </g>
  ),
});

export { favouriteFilled };
