import _ from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { GQL_GET_PRODUCT_LIST } from '../../graphql/queries';
import { SLUG_CATALOG, SLUG_ORDER } from '../../helpers/constants';
import { formatPrice } from '../../helpers/content';
import { Badge, Button } from '../../layout/_styles';
import CartItem from './CartItem';

import useCart, { isEqualChars } from '../hooks/useCart';
import useClickOutside from '../hooks/useClickOutside';
import Icon from '../Icon';

const CartSidebar = ({ change }: { change: boolean }) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [priceProducts, setPriceProducts] = useState({ price: 0, discountPrice: 0 });

  const cartRef = useRef(null);

  const { cart, increaseQuantity, decreaseQuantity, removeItem } = useCart();

  const [getProducts] = useLazyQuery(GQL_GET_PRODUCT_LIST, {
    fetchPolicy: 'no-cache',
  });

  const toggleCart = () => {
    if (!cart.length) {
      enqueueSnackbar('Добавьте товары в корзину', { variant: 'info' });
      history.push(`/${SLUG_CATALOG}`);
      return;
    }
    document.body.style.overflow = isOpen ? 'auto' : 'hidden';
    setIsOpen(!isOpen);
  };

  useClickOutside(cartRef, toggleCart, 'cart-close');

  const updateCart = (items: Product[]) => {
    const list = items.map((item) => {
      const cartItem = cart.find(
        (ci) => ci.productId === item._id && isEqualChars(ci.characteristics, item.characteristics)
      );
      return {
        ...item,
        quantity: cartItem?.quantity || 0,
      };
    });
    const totalPrice = list.reduce((acc, item) => {
      return item ? acc + (item.quantity || 0) * item.price : 0;
    }, 0);
    const totalDiscountPrice = list.reduce((acc, item) => {
      return item ? acc + (item.quantity || 0) * getDiscountPrice(item.discount, item.price) : 0;
    }, 0);
    setProducts(list);
    setPriceProducts({ price: totalPrice, discountPrice: totalDiscountPrice });
  };

  const updateProducts = async () => {
    const cartParams = cart.map((item) => ({
      _id: item.productId,
      chars: JSON.stringify(item.characteristics),
    }));
    const response = await getProducts({
      variables: { data: cartParams },
    });
    const items = _.get(response, 'data.productList', []);
    setProducts(items);
    updateCart(items);
  };

  useEffect(() => {
    if (cart.length === 0) {
      setIsOpen(false);
    }
    if (products.length !== cart.length) {
      updateProducts();
    }
    if (
      products.some((item) => {
        return cart.some(
          (ci) =>
            ci.productId === item._id &&
            isEqualChars(ci.characteristics, item.characteristics) &&
            ci.quantity !== item.quantity
        );
      }) &&
      products.length === cart.length
    ) {
      updateCart(products);
    }
  }, [cart]);

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  const totalDiscount =
    priceProducts.price &&
    priceProducts.discountPrice &&
    priceProducts.price - priceProducts.discountPrice;

  const getDiscountPrice = (discount: number, price: number) =>
    Math.round(price - (price * discount) / 100);

  const handleRemoveItem = (product: Product) => {
    removeItem(product._id, product.characteristics);
  };

  const handleIncreaseQuantity = (product: Product) => {
    increaseQuantity(product._id, product.characteristics);
  };

  const handleDecreaseQuantity = (product: Product) => {
    decreaseQuantity(product._id, product.characteristics);
  };

  return (
    <CartPosition>
      <Button id="cart-close" onClick={toggleCart} type="button" as="button" aria-label="cart">
        <Icon colorFill={change ? 'var(--white)' : 'var(--dark)'} name="cart" />
        {cart.length > 0 && <Badge>{cart.length}</Badge>}
      </Button>

      {isOpen && (
        <CartBlock as="div" ref={cartRef}>
          <Name>Корзина</Name>
          <ProductsInCart>
            {products.map((item) => (
              <CartItem
                edit
                key={`${item._id}-${item.slug}`}
                item={item}
                handleRemoveItem={handleRemoveItem}
                handleDecreaseQuantity={handleDecreaseQuantity}
                handleIncreaseQuantity={handleIncreaseQuantity}
              />
            ))}
          </ProductsInCart>
          <Total>
            <Label>Сумма заказов</Label>
            <Area>
              <p>Стоимость товаров:</p> <Gray>{formatPrice(priceProducts.price)}</Gray>
            </Area>
            {totalDiscount && (
              <Area>
                <p>Скидка:</p> <Gray>{formatPrice(totalDiscount)}</Gray>
              </Area>
            )}
            <Area>
              <Strong>ИТОГО</Strong>{' '}
              <TotalPrice>{formatPrice(priceProducts.discountPrice)}</TotalPrice>
            </Area>
            <ButtonCart onClick={toggleCart} to={`/${SLUG_ORDER}`}>
              Оформить заказ
            </ButtonCart>
          </Total>
        </CartBlock>
      )}
    </CartPosition>
  );
};

const Area = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    padding: 0;
    margin: 0;
  }
`;

const Strong = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
  text-transform: uppercase;
`;

const TotalPrice = styled.div`
  color: var(--black, #000);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 18px */
  text-transform: uppercase;
`;

const Gray = styled.div`
  color: var(--grey-dark, #7b7b7b);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

const ButtonCart = styled(Link)`
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  background: var(--black, #000);
  color: var(--white, #ffffff);
  border: 0;
  margin-top: 16px;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--hover-dark, #424242);
  }
`;

const Name = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 18px */
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 23px;
`;

const Label = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 640;
  line-height: 120%; /* 14.4px */
  text-transform: uppercase;
`;

const ProductsInCart = styled.div`
  display: grid;
  gap: 10px;
  max-height: 270px;
  overflow-y: auto;
`;

const Total = styled.div`
  display: grid;
  gap: 10px;
  padding-top: 16px;
`;

const CartBlock = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  background-color: var(--white);
  min-width: 330px;
  max-width: 330px;
  width: 100%;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 24px 12px;
`;

const CartPosition = styled.div`
  position: relative;
  width: max-content;
`;

export default CartSidebar;
