import { gql } from '@apollo/client';

const meFragment = gql`
  fragment MeFragment on Me {
    _id
    email
    name
    status
    assignment {
      roles {
        rules {
          rule
        }
      }
    }
  }
`;

export const GQL_LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_SIGNUP = gql`
  mutation Signup($name: String!, $email: String!, $password: String!) {
    signup(name: $name, email: $email, password: $password) {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_GET_ME = gql`
  query GetMe {
    me {
      ...MeFragment
    }
  }
  ${meFragment}
`;
