import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import useLocalStorage from 'components/hooks/useLocalStorage';
import Icon from 'components/Icon';
import SearchInput from 'components/SearchInput';
import CartSidebar from 'components/cart/CartSidebar';
import { SLUG_CATALOG } from '../helpers/constants';

import {
  Badge,
  Button,
  ButtonMobile,
  DropdownItem,
  DropdownLink,
  DropdownMenu,
  Icons,
  Logo,
  LogoMobile,
  MenuArea,
  MenuItem,
  MenuItems,
  MenuLink,
  MenuWrap,
  MobileMenuIcon,
  NavBar,
  NavBlock,
  SearchForm,
  SearchMainForm,
  StaticNavBar,
} from './_styles';

const Top = ({ menu }: { menu: object | null }) => {
  const ref = useRef(null);
  const history = useHistory();
  const favoritesFromStorage = useLocalStorage('favorites');

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [showMenu, setShowMenu] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const [staticMenu, setStaticMenu] = useState(true);
  const [favorites, setFavorites] = useState(favoritesFromStorage.length);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searchShow, setSearchShow] = useState(false);

  const lastScrollY = useRef(0);

  useEffect(() => {
    setFavorites(favoritesFromStorage.length);
  }, [favoritesFromStorage]);

  useEffect(() => {
    if (window !== undefined && _.get(window, 'location.pathname', '') === '/') {
      setStaticMenu(false);
    }
  }, []);

  const goFavorites = () => {
    if (!favoritesFromStorage.length) {
      enqueueSnackbar('Добавьте товары в избранное', { variant: 'info' });
      history.push(`/${SLUG_CATALOG}`);
      return;
    }
    history.push('/favorites');
  };

  const handleChangeMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    // add class to body
    document.body.classList.toggle('menu-open');
  };

  const handleMouseEnter = (id) => {
    setActiveDropdown(id);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  const searchView = () => {
    setSearchShow(!searchShow);
    if (!searchShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  useEffect(() => {
    const handleScroll = _.throttle(() => {
      const currentScrollY = window.scrollY;
      if (currentScrollY <= 1) {
        setIsAtTop(true);
      }
      if (currentScrollY <= _.get(ref, 'current.clientHeight', 145)) {
        setShowMenu(true);
      } else {
        setIsAtTop(false);
        if (currentScrollY < lastScrollY.current) {
          // Scroll up
          setShowMenu(true);
        } else if (currentScrollY > lastScrollY.current) {
          // Scroll down
          setShowMenu(false);
        }
      }

      lastScrollY.current = currentScrollY;
    }, 200); // Scroll processing no more often than once every 200 ms

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const status = showMenu ? 'visible' : 'hidden';

  const isInAction = !searchShow && showMenu && isAtTop;

  return (
    <StaticNavBar
      as="div"
      height={_.get(ref, 'current.clientHeight', 145)}
      className={`${staticMenu ? 'static' : ''}`}
    >
      <NavBar
        ref={ref}
        as="div"
        white={searchShow}
        className={!isAtTop ? `navbar ${status}` : ' navbar'}
        height={_.get(ref, 'current.clientHeight', 145)}
        position={lastScrollY.current}
      >
        <NavBlock>
          <MenuWrap>
            <MobileMenuIcon>
              <ButtonMobile
                as="button"
                visibility={isInAction && !staticMenu ? 'visible' : 'hidden'}
                active={showMobileMenu}
                onClick={handleChangeMenu}
                type="button"
                aria-label="menu"
              >
                <span aria-label="line" />
                <span aria-label="line" />
                <span aria-label="line" />
              </ButtonMobile>
              <Button
                as="button"
                onClick={searchView}
                className="search-icon-mobile"
                aria-label="search"
              >
                <Icon
                  colorFill={isInAction && !staticMenu ? 'var(--white)' : 'var(--dark)'}
                  name="search"
                />
              </Button>
            </MobileMenuIcon>
            <MenuArea as="div" active={showMobileMenu}>
              <MenuItems>
                <LogoMobile as="img" src="/assets/images/logo-dark.svg" alt="Logo" />
                {_.get(menu, 'items', [])
                  .filter((item) => item.status)
                  .map((item) => (
                    <MenuItem
                      as="li"
                      key={item._id}
                      onMouseEnter={() => handleMouseEnter(item._id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <MenuLink
                        visibility={isInAction && !staticMenu ? 'visible' : 'hidden'}
                        to={item.url}
                      >
                        {item.name}
                      </MenuLink>
                      {item.children && item.children.length > 0 && (
                        <DropdownMenu
                          as="ul"
                          className={activeDropdown === item._id ? 'active' : ''}
                        >
                          {item.children
                            .filter((child) => child.status)
                            .sort((a, b) => a.order - b.order)
                            .map((child) => (
                              <DropdownItem as="li" key={child.url}>
                                <DropdownLink
                                  visibility={isInAction && !staticMenu ? 'visible' : 'hidden'}
                                  to={`${child.url}`}
                                >
                                  {child.name}
                                </DropdownLink>
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      )}
                    </MenuItem>
                  ))}
              </MenuItems>
              <SearchForm>
                <SearchInput />
              </SearchForm>
            </MenuArea>
          </MenuWrap>

          <Logo as="div" active={isInAction && !staticMenu}>
            <Link to="/">
              <img
                src={
                  isInAction && !staticMenu
                    ? '/assets/images/logo.svg'
                    : '/assets/images/logo-dark.svg'
                }
                alt="Logo"
              />
            </Link>
          </Logo>

          <Icons>
            <Button
              onClick={searchView}
              type="button"
              as="button"
              className="search-icon"
              aria-label="search"
            >
              <Icon
                colorFill={isInAction && !staticMenu ? 'var(--white)' : 'var(--dark)'}
                name="search"
              />
            </Button>
            {/* <Button type="button" as="button" aria-label="user"> */}
            {/*  <Icon */}
            {/*    colorFill={isInAction && !staticMenu ? 'var(--white)' : 'var(--dark)'} */}
            {/*    name="user" */}
            {/*  /> */}
            {/* </Button> */}
            <Button onClick={goFavorites} type="button" as="button" aria-label="favourite">
              <Icon
                colorFill={isInAction && !staticMenu ? 'var(--white)' : 'var(--dark)'}
                name="favourite"
              />
              {favorites > 0 && <Badge>{favorites}</Badge>}
            </Button>
            <CartSidebar change={isInAction && !staticMenu} />
          </Icons>
        </NavBlock>
        <SearchMainForm as="div" active={searchShow}>
          <SearchInput handleClose={searchView} btn />
        </SearchMainForm>
      </NavBar>
    </StaticNavBar>
  );
};

export default Top;
