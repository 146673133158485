import { gql } from '@apollo/client';

export const settingFragment = gql`
  fragment SettingFragment on Setting {
    _id
    name
    code
    type
    value
    status
  }
`;

export const GQL_GET_SETTING = gql`
  query GetSetting($code: String!) {
    setting: frontendSetting(code: $code) {
      ...SettingFragment
    }
  }
  ${settingFragment}
`;
