import { useEffect, useState } from 'react';

const useLocalStorage = (key: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return [];
    }
    if (!window.localStorage) {
      return [];
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : [];
    } catch (error) {
      return [];
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const handleStorageChange = () => {
        try {
          const item = window.localStorage.getItem(key);
          setStoredValue(item ? JSON.parse(item) : []);
        } catch (error) {
          /* empty */
        }
      };

      // Storage event handler for other tabs
      window.addEventListener('storage', handleStorageChange);

      // Periodically checking for changes in the current tab
      const intervalId = setInterval(() => {
        handleStorageChange();
      }, 500);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
        clearInterval(intervalId);
      };
    }
    return () => {};
  }, [key]);
  return storedValue;
};

export default useLocalStorage;
