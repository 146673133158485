import React, { ReactElement } from 'react';

const search = ({ colorFill }: IconParams): { viewBox: string; code: ReactElement } => ({
  viewBox: '0 0 36 36',
  code: (
    <g>
      <path
        d="M24.0369 23.6772L20.3176 19.9578C21.0901 19.1001 21.5649 17.9692 21.5649 16.7267C21.5649 14.0587 19.3943 11.8881 16.7263 11.8881C14.0583 11.8881 11.8877 14.0587 11.8877 16.7267C11.8877 19.3947 14.0583 21.5653 16.7263 21.5653C17.9688 21.5653 19.0997 21.0905 19.9575 20.318L23.6768 24.0373C23.7004 24.061 23.7285 24.0798 23.7594 24.0926C23.7903 24.1054 23.8234 24.112 23.8569 24.1119C23.922 24.1119 23.9872 24.087 24.0369 24.0373C24.1364 23.9378 24.1364 23.7767 24.0369 23.6772ZM16.7263 21.056C14.3393 21.056 12.397 19.1139 12.397 16.7267C12.397 14.3395 14.3393 12.3974 16.7263 12.3974C19.1133 12.3974 21.0556 14.3395 21.0556 16.7267C21.0556 19.1139 19.1133 21.056 16.7263 21.056Z"
        fill={colorFill}
      />
    </g>
  ),
});

export { search };
