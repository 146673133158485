import React, { ReactElement } from 'react';

const cartFilled = ({ colorFill }: IconParams): { viewBox: string; code: ReactElement } => ({
  viewBox: '0 0 36 36',
  code: (
    <g>
      <path
        d={`M23.197 16.2154C23.197 13.3411 20.846 11 17.9597 
        11C15.0734 11 12.7225 13.3411 12.7225 16.2154H11L12.0707 
        23.6619C12.1697 24.3689 12.7923 24.9078 13.5081 
        24.9078H22.3939C23.1155 24.9078 23.7323 24.3747 
        23.8371 23.6619L24.9078 16.2154H23.197ZM17.9597 
        11.5795C20.526 11.5795 22.6151 13.6599 22.6151 
        16.2154H13.3044C13.3044 13.6599 15.3935 11.5795 
        17.9597 11.5795Z`}
        fill={colorFill}
      />
    </g>
  ),
});

export { cartFilled };
