import React, { ReactElement } from 'react';

const favourite = ({ colorFill }: IconParams): { viewBox: string; code: ReactElement } => ({
  viewBox: '0 0 36 36',
  code: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1292 12.3014C13.0059 12.6114 12.1687 13.4347 11.7358 14.655C11.5833 15.0846 11.5765 15.1372 11.5753 15.8949C11.5741 16.5884 11.5886 16.7319 11.6925 17.057C11.9019 17.7121 12.1684 18.1405 12.7604 18.7743C13.7605 19.8449 17.8656 23.7824 17.9817 23.7824C18.0555 23.7824 18.9334 22.9639 20.802 21.1529C22.2943 19.7067 23.5912 18.4216 23.684 18.2971C23.9222 17.9776 24.169 17.4859 24.3075 17.0548C24.4114 16.7314 24.4259 16.5888 24.4247 15.8949C24.4235 15.1372 24.4167 15.0846 24.2642 14.655C23.7075 13.086 22.4241 12.1379 20.9721 12.223C20.1256 12.2725 19.445 12.6366 18.5566 13.5151L18 14.0654L17.4434 13.5151C16.8403 12.9187 16.3521 12.5776 15.8123 12.3753C15.3497 12.2019 14.6076 12.1693 14.1292 12.3014ZM15.5777 12.8476C16.1707 13.0429 16.9728 13.6796 17.5439 14.4083C17.9716 14.954 18.0285 14.9539 18.4579 14.406C19.0513 13.6488 19.887 13.0003 20.5046 12.8177C21.7724 12.4429 23.1913 13.2763 23.7231 14.7081C24.1031 15.7312 23.9456 17.0111 23.3329 17.8785C23.116 18.1855 18.0905 23.1395 17.9959 23.1395C17.9656 23.1395 16.7701 21.9875 15.3393 20.5794C12.6015 17.8853 12.5197 17.7926 12.2767 17.1099C11.9997 16.3313 11.9992 15.4558 12.2753 14.7124C12.6663 13.6596 13.5648 12.8826 14.5593 12.7373C14.8031 12.7017 15.2973 12.7552 15.5777 12.8476Z"
        fill={colorFill}
      />
    </g>
  ),
});

export { favourite };
