import styled, { createGlobalStyle } from 'styled-components';

import PragmaticaNextVF from '../../public/assets/fonts/pragmatica-next_vf.ttf';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const H1 = styled.h1`
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;

export const H2 = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.96px;
  text-transform: uppercase;
`;

export const H3 = styled.h3`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

export const H4 = styled.h4`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
`;

export const ButtonLinkLine = styled.button`
  background: none;
  border: none;
  color: var(--dark, #000);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: none;
  &:hover,
  &:focus {
    text-decoration-line: none;
  }
`;

export const ButtonLink = styled.button`
  background: none;
  border: none;
  flex-direction: column;
  color: var(--dark, #000);
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: none;
  &:hover {
    color: var(--hover-dark, #424242);
  }
`;

export const Button = styled.button<{
  variant: 'outline' | 'filled';
  width?: string;
  margin?: string;
  disabled?: boolean;
}>`
  background: ${({ variant }) => {
    switch (variant) {
      case 'outline':
        return 'transparent';
      case 'filled':
        return 'var(--black, #000)';
      default:
        return 'transparent';
    }
  }};
  border: ${({ variant }) => {
    switch (variant) {
      case 'outline':
        return '1px solid var(--black, #000);';
      case 'filled':
        return 'none';
      default:
        return '1px solid var(--black, #000)';
    }
  }};
  color: ${({ variant }) => {
    switch (variant) {
      case 'outline':
        return 'var(--black, #000);';
      case 'filled':
        return 'var(--white, #ffffff);';
      default:
        return 'var(--black, #000)';
    }
  }};
  cursor: pointer;
  display: flex;
  height: 44px;
  margin: ${({ margin }) => margin || '0'};
  width: ${({ width }) => width || 'auto'};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  text-transform: uppercase;
  transition: all 300ms;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
  `}
  &:hover {
    color: var(--white, #ffffff);
    background: var(--hover-dark, #424242);
  }
  &:focus {
    opacity: 0.4;
  }
`;

export const CloseButton = styled.button`
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  &:before,
  &:after {
    display: block;
    content: '';
    width: 25px;
    height: 1px;
    background: #000;
    position: relative;
  }
  &:before {
    top: 1px;
    transform: rotate(45deg);
  }
  &:after {
    top: 0;
    transform: rotate(-45deg);
  }
`;

export const Description = styled.div`
  display: block;
`;

export const CheckPoint = styled.span<{ isChecked?: boolean }>`
  width: 16px;
  height: 16px;
  max-width: 16px;
  max-height: 16px;
  min-width: 16px;
  min-height: 16px;
  border: 1px solid ${({ isChecked }) => (isChecked ? 'var(--black, #000)' : '#cccccc')};
  background-color: ${({ isChecked }) => (isChecked ? 'var(--black, #000)' : 'transparent')};
  border-radius: 50%;
  margin-right: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    top: -1px;
    position: relative;
    width: 6px;
    height: 9px;
    border: 0;
    border-bottom: 1px solid var(--white, #ffffff);
    border-right: 1px solid var(--white, #ffffff);
    display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
    transform: rotate(45deg);
  }
`;

export const RenderBox = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: -1;
`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Pragmatica Next VF';
    src: url(${PragmaticaNextVF}) format('truetype');
    font-style: normal;
    font-weight: 100 900;
  }

  :disabled, .disabled, .disabled:hover {
    opacity: 0.4;
  }

  :root {
    --dark: #000000;
    --hover-dark: #424242;
    --gray-dark: #7b7b7b;
    --gray: #cccccc;
    --gray-label: #EFEFEF;
    --footer: #F2F2F2;
    --white: #ffffff;
    --red: #dd463c;
    --error: #DD463C;
    --gold: #ebd9b2;
    --metalic: linear-gradient(225deg, #f9e197 0%, #dfaf5d 100%);
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Pragmatica Next VF', sans-serif;
    min-width: 380px;

    &.menu-open {
      overflow: hidden;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  input,
  textarea,
  input:hover,
  input:focus,
  textarea:hover,
  textarea:focus,
  button:active,
  button:focus,
  input[type='text']:focus,
  input[type='text'] {
    outline: none !important;
    outline-offset: 0 !important;
    box-shadow: none !important;
    outline-color: transparent !important;
    font-variant-ligatures: none !important;
  }

  [contenteditable='true']:focus {
    outline: none !important;
  }

  *:focus {
    outline: none !important;
  }

  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  #app main {
    flex: 1;
  }

  #app footer {
    flex-shrink: 0;
  }

  .w-100 {
    width: 100%;
  }

  .slick-dots {
    bottom: -70px !important;

    li {
      width: 150px !important;
      left: -75px;

      button:before {
        content: "" !important;
        width: 330px !important;
        height: 2px !important;
        border-radius: 3px !important;
        background: #e1e1e1 !important;
      }

      &.slick-active button:before {
        background: #000 !important;
      }
    }
  }

  .open-product {
    overflow: hidden;

    &:before {
      position: fixed;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      Z-index: 1001;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
    }
  }

  button {
    transition: background 0.3s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    &:hover, &:focus {
      transition: background 0.3s;
    }
  }

  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideOutRight {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideOutLeft {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    .slick-dots {
      li {
        width: 75px !important;
        left: -37.5px;

        button:before {
          width: 150px !important;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .slick-dots {
      li {
        width: 39px !important;
        left: -10px;

        button:before {
          width: 50px !important;
        }
      }
    }
  }
`;

export { GlobalStyle };
