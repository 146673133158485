import { useState, useEffect } from 'react';
import _ from 'lodash';

import useLocalStorage from './useLocalStorage';

export const CART_STORAGE_KEY = 'cart';

interface Chars {
  _id: string;
  path: string;
  value: string;
}

interface CartItem {
  productId: string;
  characteristics: Chars[];
  quantity: number;
}

/**
 * @name isEqualChars
 * @description Function for comparing two arrays of characteristics
 * @param a cart characteristics as [{_id: string, path: string, name: string, value: string}]
 * @param b cart characteristics as [{_id: string, slug: string, values: [{_id: string, name: string, slug: string}]}]
 */
export const isEqualChars = (a: Chars[], b: Characteristic[]) => {
  if (a.length !== b.length) {
    return false;
  }
  return a.every((item) =>
    b.some(
      (i) =>
        i._id === item._id && i.slug === item.path && i.values.some((iv) => iv.slug === item.value)
    )
  );
};

/**
 * @name isEqualCharsCart
 * @description Function for comparing two arrays of characteristics
 * @param a cart characteristics as [{_id: string, path: string, name: string, value: string}]
 * @param b cart characteristics as [{_id: string, path: string, name: string, value: string}]
 */
export const isEqualCharsCart = (a: Chars[], b: Chars[]) => {
  if (a.length !== b.length) {
    return false;
  }
  return a.every((item) =>
    b.some((i) => i._id === item._id && i.path === item.path && i.value === item.value)
  );
};

/**
 * @name useCart
 * @description Hook for managing the cart
 * @returns {
 * cart, // array of products in the cart
 * addToCart, // function for adding a product to the cart
 * removeItem, // function for removing a product from the cart
 * increaseQuantity, // function for increasing the quantity of a product in the cart
 * decreaseQuantity, // function for decreasing the quantity of a product in the cart
 * getTotalPrice, // function for getting the total price of products in the cart
 * getTotalDiscount // function for getting the total discount of products in the cart
 * clearCart // function for clearing the cart
 * }
 * @example
 * const { cart, addToCart, removeItem, increaseQuantity, decreaseQuantity } = useCart();
 * addToCart('objectId', { color: 'red' }, 1);
 * removeItem('objectId', { color: 'red' });
 * increaseQuantity('objectId', { color: 'red' });
 * decreaseQuantity('objectId', { color: 'red' });
 */
const useCart = () => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const cartFromStorage = useLocalStorage(CART_STORAGE_KEY);

  useEffect(() => {
    if (cartFromStorage) {
      setCart(cartFromStorage);
    }
  }, [cartFromStorage]);

  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart));
    }
  }, [cart]);

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem(CART_STORAGE_KEY);
  };

  const addToCart = (productId: string, characteristics: Chars[], quantity: number = 1) => {
    const existingProductIndex = cart.findIndex(
      (item) =>
        item.productId === productId && isEqualCharsCart(item.characteristics, characteristics)
    );

    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += quantity;
      setCart(updatedCart);
    } else {
      const newProduct: CartItem = {
        productId,
        characteristics,
        quantity,
      };
      setCart([...cart, newProduct]);
    }
  };

  const removeItem = (productId: string, characteristics: Characteristic[]) => {
    const updatedCart = cart.filter(
      (item) =>
        !(item.productId === productId && isEqualChars(item.characteristics, characteristics))
    );
    if (updatedCart.length === 0) {
      setCart([]);
      localStorage.removeItem(CART_STORAGE_KEY);
    }
    setCart(updatedCart);
  };

  const increaseQuantity = (productId: string, characteristics: Characteristic[]) => {
    const updatedCart = cart.map((item) => {
      if (item.productId === productId && isEqualChars(item.characteristics, characteristics)) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });
    setCart(updatedCart);
  };

  const decreaseQuantity = (productId: string, characteristics: Characteristic[]) => {
    const updatedCart = cart.map((item) => {
      if (
        item.productId === productId &&
        isEqualChars(item.characteristics, characteristics) &&
        item.quantity > 1
      ) {
        return {
          ...item,
          quantity: item.quantity - 1,
        };
      }
      return item;
    });
    setCart(updatedCart);
  };

  return {
    cart,
    addToCart,
    removeItem,
    increaseQuantity,
    decreaseQuantity,
    clearCart,
  };
};

export default useCart;
