import React, { ReactElement } from 'react';

const pause = ({
  colorStroke,
  colorFill,
}: IconParams): { viewBox: string; code: ReactElement } => ({
  viewBox: '0 0 32 32',
  code: (
    <g>
      <rect x="13" y="11" width="2" height="11" fill={colorFill} />
      <rect x="17" y="11" width="2" height="11" fill={colorFill} />
      <circle cx="16" cy="16" r="15.5" fill="none" stroke={colorStroke} />
    </g>
  ),
});

export { pause };
