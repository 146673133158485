import React, { useEffect } from 'react';
import _ from 'lodash';

import ErrorBoundary from '../ErrorBoundary';
import { insertHtmlElements } from '../helpers/content';
import Top from './_top';
import Footer from './_footer';

export default (
  Component: any,
  props: {
    me?: object;
    content: object | null;
  }
): any => {
  const { me, content } = props;

  return (masterProps: any) => {
    useEffect(() => {
      const header = _.get(content, 'header.value', null);
      const footer = _.get(content, 'footer.value', null);
      if (header) insertHtmlElements(header, 'head');
      if (footer) insertHtmlElements(footer, 'footer');
    }, [content]);

    return (
      <ErrorBoundary {...masterProps}>
        <Top menu={_.get(content, 'mainMenu')} />
        <Component {...masterProps} />
        <Footer content={content} />
      </ErrorBoundary>
    );
  };
};
