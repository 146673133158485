import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import _ from 'lodash';
import { errorsGQL } from './errors';

const isProduction = process.env.NODE_ENV === 'production';

let apolloClient: ApolloClient<any> | null = null;

const isBrowser = typeof window !== 'undefined';

// @ts-ignore - state is defined in the server
const state = isBrowser ? window.__APOLLO_STATE__ : '';

const graphQLUrl =
  (isBrowser ? process.env.RAZZLE_API_GRAPHQL_PATH : process.env.RAZZLE_API_GRAPHQL_SSR_PATH) ||
  'http://localhost:3000/graphql';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpLink = new HttpLink({
  fetch,
  uri: graphQLUrl,
  fetchOptions: {
    credentials: 'include',
    mode: 'cors',
  },
});

// const wsLink = isBrowser
//   ? new GraphQLWsLink(
//       createClient({
//         url: graphQLUrl
//           .replace('http://', 'ws://')
//           .replace('https://', 'wss://')
//           .replace('graphql', 'subscriptions'),
//       })
//     )
//   : undefined;

const createInstance = (res?: any, req?: any) => {
  const removeTypenameLink = removeTypenameFromVariables();

  let cache = new InMemoryCache();
  cache = cache.restore(state);

  let link: ApolloLink = httpLink;
  let authLink: ApolloLink | undefined;

  if (_.get(req, 'cookies.token')) {
    const { token = null } = req.cookies;
    authLink = setContext((__, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));
  }

  const errorLink = errorsGQL(res);

  if (authLink) {
    link = authLink.concat(link);
  }

  // if (isBrowser && wsLink) {
  //   link = split(
  //     ({ query }) => {
  //       const definition = getMainDefinition(query);
  //       return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  //     },
  //     // wsLink,
  //     link
  //   );
  // }

  link = from([errorLink, removeTypenameLink, link]);

  return new ApolloClient({
    link,
    cache,
    ssrMode: !isBrowser,
    ssrForceFetchDelay: 100,
    devtools: {
      enabled: !isProduction,
    },
    defaultOptions,
  });
};

export function createApolloClient(res?: any, req?: any) {
  if (!apolloClient) {
    // eslint-disable-next-line no-console
    console.log('Apollo frontend client created 🚀');
    apolloClient = createInstance(res, req);
  }
  return apolloClient;
}
