import { gql } from '@apollo/client';
import { SLUG_DELIVERY, SLUG_PRIVACY } from '../../helpers/constants';
import { metaFragment } from './common';

export const pageFragment = gql`
  fragment PageFragment on Page {
    _id
    name
    slug
    description
    meta {
      ...MetaFragment
    }
    blocks {
      id
      type
      content
      container
      gridSpan
      title
      description
      categories {
        _id
        name
        slug
      }
      products {
        _id
        name
        slug
        count
        price
        discount
        status
        color {
          _id
          name
          code
          slug
          status
        }
        # Virtuals
        is_new
        files {
          path
          mime_type
          model
          model_id
          format
        }
        brand {
          _id
          name
          slug
          status
        }
      }
      news
      link
      file {
        path
        mime_type
        model
        model_id
        format
      }
      tools
      overlayColor
    }
    created_at
    updated_at
    status
  }
  ${metaFragment}
`;

export const GQL_GET_CART_PAGES = gql`
  query GetCartPages {
    privacy: frontendPage(slug: "${SLUG_PRIVACY}") {
      ...PageFragment
    }
    delivery: frontendPage(slug: "${SLUG_DELIVERY}") {
      ...PageFragment
    }
  }
  ${pageFragment}
`;

export const GQL_GET_PAGE = gql`
  query GetPage($slug: String!) {
    page: frontendPage(slug: $slug) {
      ...PageFragment
    }
  }
  ${pageFragment}
`;
