import { gql } from '@apollo/client';

import { fileFragment, metaFragment } from './common';
import { pageFragment } from './page';

export const productListFragment = gql`
  fragment ProductListFragment on Product {
    _id
    name
    slug
    popularity
    status
    price
    discount
    # Virtuals
    is_new
    characteristic_values {
      char_id
      status
      values {
        val_id
        status
      }
    }
    characteristics {
      _id
      slug
      type
      status
      name
      values {
        _id
        name
        slug
        status
      }
    }
    color {
      name
    }
    files {
      path
      mime_type
      model
      model_id
      format
    }
    category {
      _id
      name
      slug
      status
      parent_id
    }
    brand {
      _id
      name
      slug
      status
    }
  }
`;

const productFragment = gql`
  fragment ProductFragment on Product {
    _id
    meta {
      ...MetaFragment
    }
    name
    slug
    popularity
    status
    sku
    description
    count
    price
    discount
    status
    created_at
    updated_at
    color {
      _id
      name
      code
      slug
      status
    }
    # Virtuals
    is_new
    characteristics {
      _id
      name
      slug
      type
      status
      values {
        _id
        name
        slug
        status
      }
    }
    characteristics_list {
      _id
      name
      slug
      type
      status
      values {
        _id
        name
        slug
        status
      }
    }
    files {
      ...FileFragment
    }
    similar {
      ...ProductListFragment
    }
    attached {
      ...ProductListFragment
    }
    category {
      _id
      name
      slug
      status
      parent_id
      parent {
        name
        slug
      }
    }
    brand {
      _id
      name
      slug
      status
    }
    collection {
      _id
      name
    }
  }
  ${productListFragment}
  ${fileFragment}
  ${metaFragment}
`;

export const GQL_GET_PRODUCTS = gql`
  query GetProducts($product_ids: [ID], $query: QueryItems) {
    products: frontendProducts(product_ids: $product_ids, query: $query) {
      total
      page {
        ...PageFragment
      }
      filters {
        characteristics {
          _id
          name
          slug
          type
          status
          values {
            _id
            name
            slug
            status
          }
        }
        price {
          min
          max
        }
        categories {
          _id
          name
          slug
          status
          parent_id
        }
        subcategories {
          _id
          name
          slug
          status
          parent_id
        }
        brands {
          _id
          name
          slug
          status
        }
        colors {
          _id
          name
          code
          slug
          status
        }
      }
      items {
        ...ProductListFragment
      }
    }
  }
  ${productListFragment}
  ${pageFragment}
`;

export const GQL_GET_PRODUCT = gql`
  query GetProduct($_id: ID, $slug: String) {
    product: frontendProduct(_id: $_id, slug: $slug) {
      ...ProductFragment
    }
  }
  ${productFragment}
`;

export const GQL_GET_PRODUCT_LIST = gql`
  query GetProductList($data: [CartProduct]) {
    productList: frontendProductList(data: $data) {
      ...ProductListFragment
    }
  }
  ${productListFragment}
`;
