import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Icon from 'components/Icon';
import { GQL_SEARCH } from '../graphql/queries/common';

const SearchInput = ({ btn, handleClose }: { btn?: boolean; handleClose?: () => void }) => {
  const [inputValue, setInputValue] = useState('');

  const [products, setProducts] = useState<Product[]>([]);

  const [searchProducts] = useLazyQuery(GQL_SEARCH);

  const handleChange = (event) => {
    const value = _.get(event, 'target.value', '');
    setInputValue(value);

    if (value.length < 3) {
      setProducts([]);
      return;
    }

    const debouncedSearch = _.debounce(async () => {
      const { data } = await searchProducts({
        variables: {
          query: value,
        },
      });

      const productsData = _.get(data, 'search', []);
      if (_.isEmpty(productsData)) {
        setProducts([]);
        return;
      }
      setProducts((prev) => {
        const filtered = prev.filter((item) => !productsData.find((el) => el._id === item._id));
        return [...filtered, ...productsData];
      });
    }, 300);

    debouncedSearch();
  };

  const onSearch = (value: string) => {

  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearch(inputValue);
      setInputValue('');
    }
  };

  const handleSearch = () => {
    onSearch(inputValue);
    setInputValue('');
  };

  return (
    <InputArea>
      <InputContainer>
        <Border>
          <StyledInput
            as="input"
            type="text"
            value={inputValue}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
          {!inputValue && (
            <Placeholder>
              {!btn && <Icon colorFill="var(--dark)" name="search" width={40} height={40} />}
              <span>Поиск</span>
            </Placeholder>
          )}
          {btn && (
            <Button as="button" type="button" onClick={handleSearch}>
              Поиск
            </Button>
          )}
        </Border>
        {btn && <ButtonClose as="button" type="button" onClick={handleClose} />}
      </InputContainer>
      {products.length > 0 && (
        <div>
          {products.map((product) => (
            <div key={product._id}>{product.name}</div>
          ))}
        </div>
      )}
    </InputArea>
  );
};

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding: 20px 0;
  @media (max-width: 800px) {
    max-width: 700px;
  }
  @media (max-width: 768px) {
    max-width: 542px;
    padding: 20px;
  }
`;

const ButtonClose = styled.button`
  width: 36px;
  height: 36px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  flex-direction: column;
  margin-left: 29px;
  &:before,
  &:after {
    content: '';
    position: relative;
    width: 26px;
    height: 1px;
    background-color: var(--black, #000);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    top: -1px;
    transform: rotate(-45deg);
  }
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    &:before,
    &:after {
      width: 20px;
    }
  }
  @media (max-width: 625px) {
    display: none;
  }
`;

const Button = styled.button`
  background: var(--black, #000);
  color: var(--white, #fff);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  padding: 10px 12px;
  border: none;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  max-width: 736px;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    max-width: 700px;
  }
  @media (max-width: 768px) {
    max-width: 542px;
    padding: 0 20px;
  }
`;

const Border = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray);
  width: 100%;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 8px 16px;
  border: 0;
  height: 40px;
  border-radius: 0;
  text-align: center;
`;

const Placeholder = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: var(--gray-dark);
  width: 110%;
  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    transform: translate(0%, -8%);
  }
  @media (max-width: 768px) {
    span {
      transform: translate(-25%, 30%);
    }
  }
  @media (max-width: 625px) {
    span {
      transform: translate(-70%, 30%);
    }
  }
`;

SearchInput.defaultProps = {
  btn: false,
  handleClose: () => {},
};

export default SearchInput;
