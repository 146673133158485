import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { formatPrice, getImageUrls } from '../../helpers/content';

const CartItem = ({
  item,
  handleRemoveItem,
  handleDecreaseQuantity,
  handleIncreaseQuantity,
  edit = true,
}: {
  edit: boolean;
  item: Product;
  handleRemoveItem: (product: Product) => void;
  handleDecreaseQuantity: (product: Product) => void;
  handleIncreaseQuantity: (product: Product) => void;
}) => {
  const getDiscountPrice = (discount: number, price: number) =>
    Math.round(price - (price * discount) / 100);

  const getCharName = () => {
    if (!item.characteristics && !item.color) {
      return <div />;
    }
    const name = [];
    if (item.color) {
      name.push(
        <Char as="div" key="color">
          <CharName>Цвет:</CharName>
          <CharValue>{item.color.name}</CharValue>
        </Char>
      );
    }
    item.characteristics.forEach((char: Characteristic, i) => {
      const charValue = _.get(char, 'values.0', {}) as CharacteristicValue;
      if (!charValue) {
        return;
      }
      name.push(
        <Char as="div" key={charValue.name || `char-${i + 1}`}>
          <CharName>{char.name}:</CharName>
          <CharValue>{charValue.name}</CharValue>
        </Char>
      );
    });
    return name;
  };

  return (
    <ProductCart as="div" key={`${item._id}-${item.slug}`}>
      <Image as="div" url={getImageUrls(_.get(item, 'files.0', '')).thumbnail} />
      <ProductContent>
        {edit && (
          <ButtonRemove as="button" type="button" onClick={() => handleRemoveItem(item)}>
            Удалить
          </ButtonRemove>
        )}
        <ProductName>{item.name}</ProductName>
        {getCharName()}
        <Actions>
          <Price>
            <Current>
              {formatPrice(
                item.discount ? getDiscountPrice(item.discount, item.price) : item.price
              )}
            </Current>
            {!!item.discount && <Old>{formatPrice(item.price)}</Old>}
          </Price>
          <Counter>
            <Quantity>
              {edit && (
                <ButtonAction
                  as="button"
                  type="button"
                  disabled={item.quantity === 1}
                  onClick={() => handleDecreaseQuantity(item)}
                >
                  –
                </ButtonAction>
              )}
              {(edit || (!edit && item.quantity !== 0)) && (
                <p>
                  {!edit && item.quantity > 0 ? 'x' : ''}
                  {item.quantity}
                </p>
              )}
              {edit && (
                <ButtonAction
                  as="button"
                  type="button"
                  onClick={() => handleIncreaseQuantity(item)}
                >
                  +
                </ButtonAction>
              )}
            </Quantity>
            <TotalProductPrice>
              {formatPrice(
                (item.discount ? getDiscountPrice(item.discount, item.price) : item.price) *
                  item.quantity
              )}
            </TotalProductPrice>
          </Counter>
        </Actions>
      </ProductContent>
    </ProductCart>
  );
};

const Counter = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
`;

const Char = styled.div`
  display: flex;
`;

const CharName = styled.div`
  font-size: 12px;
  font-style: normal;
  line-height: 70%;
  margin-right: 3px;
  font-weight: 400;
  color: var(--grey-dark, #7b7b7b);
`;

const CharValue = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 70%;
`;

const TotalProductPrice = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

const Price = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
`;

const Current = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

const Old = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
  letter-spacing: 0.4px;
  text-decoration: line-through;
  color: var(--grey-dark, #7b7b7b);
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
`;

const ButtonAction = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Quantity = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  text-align: center;
  gap: 6px;
  margin-right: 20px;
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
  }
`;

const ProductCart = styled.div`
  padding: 16px 0;
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--grey, #ccc);
  height: min-content;
`;

const ButtonRemove = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: var(--grey-dark, #7b7b7b);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.4px;
  text-transform: none;
  position: absolute;
  padding-right: 12px;
  right: 4px;
  top: 0;
  &:after,
  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 8px;
    height: 1px;
    background-color: var(--grey-dark, #7b7b7b);
    right: -2px;
    top: 6px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const Image = styled.div<{ url: string }>`
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
  width: 60px;
  height: 73px;
  margin-right: 4px;
`;

const ProductContent = styled.div`
  display: grid;
  gap: 10px;
  position: relative;
  width: 100%;
`;

const ProductName = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

export default CartItem;
