import { gql } from '@apollo/client';

export const orderFragment = gql`
  fragment OrderFragment on Order {
    _id
    number
    shipping
    products {
      _id
      sku
      name
      file {
        path
        model
        format
      }
      color
      quantity
      price
      discount
      characteristics {
        car_id
        name
        values {
          val_id
          name
        }
      }
    }
    status
    created_at
    updated_at
    #Virtual fields
    shop {
      name
      location
      description
    }
  }
`;

export const GQL_CREATE_ORDER = gql`
  mutation CreateOrder($order: InputOrder!) {
    createOrder(order: $order)
  }
`;

export const GQL_GET_ORDER = gql`
  query GetOrder($_id: ID!) {
    order: frontendOrder(_id: $_id) {
      ...OrderFragment
    }
  }
  ${orderFragment}
`;
