import _ from 'lodash';

import { config } from '../config';
import { IMAGE_SIZE_MEDIUM, IMAGE_SIZE_SMALL, SLUG_CATALOG } from './constants';

/**
 * @function
 * @name sortByKey
 * @description Sorting array by names a-z
 * @param {object} a from
 * @param {object} b to
 * @param {string} key name for sorting
 * @return {int} rule for sorting function
 * @example .sort((a,b) => sortByNames(a, b))
 */

export const sortByKey = (a?: any, b?: any, key: string = 'name') => {
  const valueA = _.get(a, key, '').trim().toString().toLowerCase();
  const valueB = _.get(b, key, '').trim().toString().toLowerCase();

  const numA = parseFloat(valueA);
  const numB = parseFloat(valueB);

  if (!_.isNaN(numA) && !_.isNaN(numB)) {
    if (numA < numB) {
      return -1;
    }
    if (numA > numB) {
      return 1;
    }
    return 0;
  }

  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;
};
/**
 * @name getCharacteristics
 * @description Get characteristics from slug
 * @param {String} slug - slug
 * @return {Object<{ colorSlug: string, characteristicsItems: [{ charSlug: string, charValueSlug: string }] }>}
 */
export const getCharacteristics = (
  slug: string
): { colorSlug: string; characteristicsItems: { [key: string]: string } } => {
  const characteristicsItems = {};
  let colorSlug = '';
  // Split characteristic values
  const splitChar = (param) => {
    const parts = param.split('-');
    for (let i = 0; i < parts.length; i += 2) {
      if (parts[i] === 'color') {
        colorSlug = parts[i + 1];
      } else {
        characteristicsItems[parts[i]] = parts[i + 1];
      }
    }
  };

  // Check if we have a characteristic in the slug
  if (slug.includes('-char-')) {
    const params = slug.split('-char-');
    if (_.get(params, 1)) {
      const characteristicString = _.get(params, 1);
      if (characteristicString.includes('-and-')) {
        const characteristicValues = characteristicString.split('-and-');
        characteristicValues.forEach(splitChar);
      } else {
        splitChar(characteristicString);
      }
    }
  }
  return { colorSlug, characteristicsItems };
};
/**
 * @name parseCatalogFilters
 * @description Parse filters from url
 * @param {string} url
 * @return {Object}
 * @example
 * parseFilters('/catalog/category-1-and-category-2') // { category: ['category-1', 'category-2'] }
 */
export const parseCatalogFilters = (url: string): { [key: string]: string[] } => {
  const filters: { [key: string]: string[] } = {};
  if (!url.includes(SLUG_CATALOG)) {
    return filters;
  }
  const filterString = url.split(`/${SLUG_CATALOG}/`)[1];

  if (!filterString) {
    return filters;
  }
  const filterPairs = filterString.split('-and-');

  filterPairs.forEach((pair) => {
    const [key, ...values] = pair.split('-');
    filters[key] = values.join('-').split('-or-');
  });

  return filters;
};
/**
 * @name getVideoPoster
 * @description Get video poster
 * @param {FileItem} item - file
 * @return {string} - video poster url
 */
export const getVideoPoster = (item?: FileItem): string => {
  if (!item) {
    return '';
  }
  return `${config.url.cloud}/${item.model}/poster_${item.path}.jpeg`;
};
/**
 * @name getVideoUrl
 * @description Get video urls
 * @param {FileItem} item
 * @return {Object}
 * @example
 * getVideoUrl(item) // {
 *    mp4: 'https://storage.yandexcloud.net/khoklomadev/model/path.mp4',
 *    webm: 'https://storage.yandexcloud.net/khoklomadev/model/path.webm'
 * }
 * @param item
 */
export const getVideoUrl = (item?: FileItem): { mp4: string; webm: string } => {
  if (!item) {
    return { mp4: '', webm: '' };
  }
  return {
    mp4: `${config.url.cloud}/${item.model}/${item.path}.mp4`,
    webm: `${config.url.cloud}/${item.model}/${item.path}.webm`,
  };
};
/**
 * @name getImageUrls
 * @description Get image urls
 * @param {FileItem} item
 * @return {Object}
 * @example
 * getImageUrls(item) // {
 *   original: 'https://storage.yandexcloud.net/khoklomadev/model/path.svg',
 *   medium: 'https://storage.yandexcloud.net/khoklomadev/model/600__path.svg',
 *   thumbnail: 'https://storage.yandexcloud.net/khoklomadev/model/250__path.svg'
 * }
 */
export const getImageUrls = (
  item?: FileItem
): { original: string; medium: string; thumbnail: string } => {
  if (!item) {
    return { original: '', medium: '', thumbnail: '' };
  }
  return {
    original: `${config.url.cloud}/${item.model}/${item.path}.${item.format}`,
    medium: `${config.url.cloud}/${item.model}/${IMAGE_SIZE_MEDIUM}__${item.path}.${item.format}`,
    thumbnail: `${config.url.cloud}/${item.model}/${IMAGE_SIZE_SMALL}__${item.path}.${item.format}`,
  };
};

/**
 * @name getFileUrl
 * @description Get file url
 * @param {FileItem} item
 * @return {String}
 * @example
 * getFileUrl(item) // https://storage.yandexcloud.net/khoklomadev/model/path.mp4
 */
export const getFileUrl = (item?: FileItem): string => {
  if (!item) {
    return '';
  }
  return `${config.url.cloud}/${item.model}/${item.path}.${item.format}`;
};

/**
 * @name formatPrice
 * @param {number|string} price - price
 * @return {string}
 * @example
 * formatPrice(1000) // 1 000 ₽
 */
export const formatPrice = (price?: number | string): string => {
  if (!price && !_.isNumber(price)) {
    return '';
  }
  const priceNumber = typeof price === 'string' ? parseInt(price, 10) : price;
  return `${priceNumber.toLocaleString('ru-RU')} ₽`;
};

/**
 * @name declinationName
 * @description Declination of the name
 * @param {number} count
 * @param {[String]} words
 * @return {String}
 * @example
 * declinationName(1, ['товар', 'товара', 'товаров']) // товар
 */
export const declinationName = (count: number = 0, words: string[] = []) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return words[+count % 100 > 4 && +count % 100 < 20 ? 2 : cases[Math.min(+count % 10, 5)]];
};

/**
 * @name stripTags
 * @description Remove tags
 * @param {string} input dom or string value with tags
 * @return {string} without tags of new values
 */

export const stripTags = (input) => {
  if (input === null || input === '' || typeof input === 'undefined') {
    return '';
  }
  const string = input.toString();
  return string.replace(/(<([^>]+)>)/gi, '');
};

/**
 * @name insertHtmlElements
 * @description Insert elements from html string to document
 * @param {string} htmlString
 * @param {string} position - position to insert elements ('head' or 'afterFooter')
 * @example
 * insertHtmlElements('<script src="https://example.com/script.js"></script>', 'head')
 * insertHtmlElements('<script>console.log("Hello")</script>', 'afterFooter')
 */
export const insertHtmlElements = (htmlString: string, position: 'head' | 'footer') => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const insertScript = (script: HTMLScriptElement) => {
    const existingScript = Array.from(document.querySelectorAll('script')).find(
      (s) => s.innerHTML === script.innerHTML && s.src === script.src
    );
    if (!existingScript) {
      const newScript = document.createElement('script');
      newScript.innerHTML = script.innerHTML;
      if (script.src) newScript.src = script.src;
      return newScript;
    }
    return null;
  };

  const insertLink = (link: HTMLLinkElement) => {
    const existingLink = document.querySelector(`link[href="${link.href}"]`);
    if (!existingLink) {
      const newLink = document.createElement('link');
      newLink.rel = link.rel;
      newLink.href = link.href;
      return newLink;
    }
    return null;
  };

  const insertMeta = (meta: HTMLMetaElement) => {
    const existingMeta = document.querySelector(`meta[name="${meta.name}"]`);
    if (!existingMeta) {
      const newMeta = document.createElement('meta');
      newMeta.name = meta.name;
      newMeta.content = meta.content;
      return newMeta;
    }
    return null;
  };

  if (position === 'head') {
    doc.querySelectorAll('script').forEach((script) => {
      const newScript = insertScript(script);
      if (newScript) document.head.appendChild(newScript);
    });

    doc.querySelectorAll('link').forEach((link) => {
      const newLink = insertLink(link);
      if (newLink) document.head.appendChild(newLink);
    });

    doc.querySelectorAll('meta').forEach((meta) => {
      const newMeta = insertMeta(meta);
      if (newMeta) document.head.appendChild(newMeta);
    });
  } else if (position === 'footer') {
    const footerElement = document.querySelector('#app');
    if (footerElement) {
      doc.querySelectorAll('script').forEach((script) => {
        const newScript = insertScript(script);
        if (newScript) footerElement.insertAdjacentElement('afterend', newScript);
      });
    }
  }
};
