import loadable, { LoadableComponent } from '@loadable/component';
import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import { GQL_GET_ME } from './graphql/queries';
import { GQL_GET_MAIN_CONTENT } from './graphql/queries/common';
import {
  SLUG_CATALOG,
  SLUG_FAVORITES,
  SLUG_LOGIN,
  SLUG_ORDER,
  SLUG_PRODUCT,
  SLUG_SIGNUP,
} from './helpers/constants';
import Master from './layout/Master';

const fallback = () => (
  <div className="loader">
    <div className="bar" />
  </div>
);

const Home = loadable(() => import('./screens/Home'), {
  ssr: true,
  fallback: fallback(),
});
const Page = loadable(() => import('./screens/Page'), {
  ssr: true,
  fallback: fallback(),
});
const Catalog = loadable(() => import('./screens/catalog/Catalog'), {
  ssr: true,
  fallback: fallback(),
});
const Product = loadable(() => import('./screens/catalog/Product'), {
  ssr: true,
  fallback: fallback(),
});
const Favorites = loadable(() => import('./screens/Favorites'), {
  ssr: false,
  fallback: fallback(),
});
const OrderUpdate = loadable(() => import('./screens/order/OrderUpdate'), {
  ssr: false,
  fallback: fallback(),
});
const Register = loadable(() => import('./screens/Register'), {
  ssr: true,
  fallback: fallback(),
});
const Login = loadable(() => import('./screens/Login'), {
  ssr: true,
  fallback: fallback(),
});
const ErrorScreen = loadable(() => import('./screens/ErrorScreen'), {
  ssr: true,
  fallback: fallback(),
});

const RouteNavigator = () => {
  const { data } = useQuery(GQL_GET_ME);
  const { data: content } = useQuery(GQL_GET_MAIN_CONTENT, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const me = _.get(data, 'me', {});

  const SiteMaster: any = (
    page: LoadableComponent<any>,
    props: {
      me: object | null;
      menus: object | null;
    }
  ) => Master(page, { ...props, content });

  return (
    <Switch>
      <Redirect to="/" from="/main" />
      <Route path="/" component={SiteMaster(Home, { me })} exact />
      <Route path={`/${SLUG_SIGNUP}`} component={SiteMaster(Register, { me })} exact />
      <Route path={`/${SLUG_LOGIN}`} component={SiteMaster(Login, { me })} exact />
      <Route
        path={`/${SLUG_CATALOG}/:params([0-9a-z-]*)?`}
        component={SiteMaster(Catalog, { me })}
        exact
      />
      <Route
        path={`/${SLUG_PRODUCT}-:url([0-9a-z-]+)`}
        component={SiteMaster(Product, { me })}
        exact
      />
      <Route path={`/${SLUG_FAVORITES}`} component={SiteMaster(Favorites, { me })} exact />
      <Route path={`/${SLUG_ORDER}`} component={SiteMaster(OrderUpdate, { me })} exact />
      <Route
        path={`/${SLUG_ORDER}/step-:number([0-9]+)`}
        component={SiteMaster(OrderUpdate, { me })}
        exact
      />
      <Route
        path={`/${SLUG_ORDER}/success-:id([0-9a-fA-F]{24})`}
        component={SiteMaster(OrderUpdate, { me })}
        exact
      />
      <Route path="/:url" component={SiteMaster(Page)} exact />
      <Route path="/*" component={SiteMaster(ErrorScreen)} exact />
    </Switch>
  );
};

export default RouteNavigator;
