import React, { ReactElement } from 'react';

const cart = ({ colorStroke, colorFill }: IconParams): { viewBox: string; code: ReactElement } => ({
  viewBox: '0 0 36 36',
  code: (
    <g>
      <path
        d="M23.2429 16.2615C23.2429 13.3872 20.8919 11.0461 18.0056 11.0461C15.1193 11.0461 12.7684 13.3872 12.7684 16.2615H11.0459L12.1166 23.708C12.2156 24.415 12.8382 24.9539 13.554 24.9539H22.4398C23.1614 24.9539 23.7782 24.4208 23.883 23.708L24.9537 16.2615H23.2429ZM18.0056 11.6256C20.5719 11.6256 22.661 13.706 22.661 16.2615H13.3503C13.3503 13.706 15.4394 11.6256 18.0056 11.6256ZM23.3069 23.6269C23.2487 24.0499 22.8763 24.3744 22.4398 24.3744H13.554C13.1233 24.3744 12.7509 24.0557 12.6927 23.6269L11.7151 16.841H24.2787L23.3069 23.6269Z"
        fill={colorFill}
      />
    </g>
  ),
});

export { cart };
